// Global Overrides Template Level Only


//------- ng-accordion -------
.accordion {
  .card {
    margin: 0;
    //background-color: transparent !important;

    //&:not(:last-of-type) {
      .card-header {
        border-bottom: 1px solid $border-color;
      }
    //}

    &:last-of-type {
      .card-header button {
        //padding-bottom: 0;
      }

      .card-body {
        padding-bottom: 0;
      }
    }

    .card-header {
      padding: 0;

      button {
        padding: 1.5rem;
        width: 100%;
        text-align: start;

        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

// for dark and transparent layouts
html body.layout-dark {
  .accordion .card {
    box-shadow: none;
  }

  &:not(.layout-transparent) {
    .accordion .card .card-header {
      border-color: $dl-border-color;
    }
  }

  &.layout-transparent {
    .accordion .card .card-header {
      border-color: $tl-border-color;

      button:not([disabled]) {
        color: map-get($primary-color, lighten-4);
      }
    }
  }
}



//------- horizontal timeline -------
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}



//------- time picker -------
.ngb-tp-input-container button:hover {
  box-shadow: none;
}


//------- Chartist Tooltip -------
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}

//------- Subordinate and Owner colors -------
.transp-background {
  background-color: transparent !important;
}

.sub-color {
  color: #cddc39 !important;
  font-size: large;
}

.sub-background {
  border: 1px solid #474748;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: #262626 !important;
}

.btn-sub-color {
  color: #000;
  background-color: #cddc39;
  border-color: #cddc39;
}

.btn-sub-color:hover {
  color: #000;
  background-color: #dfef47;
  border-color: #dfef47;
}

.owner-color {
  color: #ff9800 !important;
  font-size: large;
}

.owner-background {
  border: 1px solid #474748;
  border-radius: 10px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  background: #2e2e2e !important;
}

.btn-owner-color {
  color: #000;
  background-color: #ff9800;
  border-color: #ff9800;
}

.btn-owner-color:hover {
  color: #000;
  background-color: #fda11a;
  border-color: #fda11a;
}
